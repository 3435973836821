<template>
  <div class="page flex-col">
    <div class="layer1 flex-col">
      <div class="bd4 flex-row">
        <div class="outer1 flex-col">
          <img
            class="img2"
            referrerpolicy="no-referrer"
            :src="data.supplierLogoImgUrl"
            v-if="data.supplierLogoImgUrl != ''"
          />
          <img
            class="img2"
            referrerpolicy="no-referrer"
            src="@/assets/SketchPng5cd76ebd73be15f1290f157390c017278111242c0fe841f70d9af33b1bef7e4c.png"
            v-else
          />
        </div>
        <span class="word4">{{ data.channelName }}</span>
      </div>
      <div class="bd5 flex-col"></div>
      <div class="bd6 flex-row">
        <span class="info2_">¥</span>
        <span class="txt1_">{{ data.payAmount }}</span>
      </div>
      <span class="word5">注意事项：</span>
      <div class="bd7">
        <span class="txt2">1</span>
        <span class="word6">
          、请核实付款金额与订单金额是否一致，若疑问请与商户联系
        </span>
        <span class="infoBox1">
          <br />
          2
        </span>
        <span class="word7">
          、若因商品缺货或少送等原因，请与商户沟通调整付款金额后再扫码支付
        </span>
      </div>
      <div class="bd8 flex-col" @click="pay" v-if="text == '立即支付'">
        <span class="word8">{{text}}</span>
      </div>
      <div class="bd8 flex-col" v-if="text == '订单已支付完成'" style="background:#ccc;">
        <span class="word8">{{text}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import { getDetail } from "../../api/detail";
// import logo from '../../assets/img/SketchPng5cd76ebd73be15f1290f157390c017278111242c0fe841f70d9af33b1bef7e4c.png'
export default {
  data() {
    return {
      // logo:require('SketchPng5cd76ebd73be15f1290f157390c017278111242c0fe841f70d9af33b1bef7e4c.png'),
      constants: {},
      data: {},
      text: "立即支付",
    };
  },
  created() {
    console.log(this.$route.query);
    this.getData(this.$route.query);
  },
  methods: {
    getData(data) {
      console.log(this);
      getDetail(data).then((res) => {
        console.log(res);
        this.data = res.data;
        if (res.data.orderStatus == 3) {
          // 订单已支付完成
          this.text = "订单已支付完成";
        }
      });
      // this.rq.get("/order/searchOrderInfoByQrcode", data).then((res) => {
      //   console.log(res);
      // });
    },
    pay() {
      var ua = navigator.userAgent.toLowerCase();
      console.log(ua);
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        // ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            this.weChact();
          } else {
            alert("不在小程序里");
          }
        });
      } else {
        alert("不在微信里");
      }
    },

    // 小程序中执行数据交互
    weChact() {
      // const postJson = { funName: 'getUserLoginStatus' }
      // window.postMessage(JSON.stringify(postJson), '*')
      if (this.$route.query.type == 1) {
        wx.miniProgram.postMessage({
          data: this.$route.query,
        });
        wx.miniProgram.redirectTo({
          url:
            "/pages/orderdetail/orderdetail?orderId=" +
            this.$route.query.orderId+"&supplierId="+this.$route.query.supplierId+"&buyerId="+this.$route.query.buyerId, // 小程序中页面的路径
        });
      } else {
        wx.miniProgram.postMessage({
          data: this.$route.query,
        });
        wx.miniProgram.navigateBack();
      }
    },
  },
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />