import Vue from 'vue'
import VueRouter from 'vue-router'
import lanhu_fukuanyemian2 from '../views/lanhu_fukuanyemian2/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: "/lanhu_fukuanyemian2"
  },
  {
    path: '/lanhu_fukuanyemian2',
    name: 'lanhu_fukuanyemian2',
    component: lanhu_fukuanyemian2
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
