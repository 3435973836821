import axios from "axios";
// import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
// import _ from "lodash";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 600000, // 请求超时时间
});

// request interceptor
// request拦截器
service.interceptors.request.use(
  (config) => {
    // if (store.getters.token) {
    //   config.headers['X-Token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    // }
    // 设置client_type常量
    config.headers.common["client_type"] = "manager_web";
    // 设置token常量
    // config.headers.common['token'] = getToken()
    config.headers.common["Cache-Control"] = "no-cache";
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response) => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    // console.log(_.isEmpty(response))
    // console.log(response.status)
    return response.data
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
